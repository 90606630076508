import React, {Fragment, useEffect} from 'react';
import useFetch from "./useFetch";
import {API_URL} from "../components/constent";




const FetchBlock = (props) => {

    const { data, loading, error } = useFetch(API_URL + '/get_block/' + props.block);

    useEffect(() => {
        // Update the document title using the browser API
    }, []);

    return (
        <Fragment>
            {data &&
                <span dangerouslySetInnerHTML={{__html: data.response.content }}></span>
            }
        </Fragment>
    );
}

export default FetchBlock;


const useBlock = (block) => {

    const { data, loading, error } = useFetch(API_URL + '/get_block/' + block);

    return data?.response;
}

export {useBlock};
