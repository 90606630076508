import React, {useContext} from 'react';
import { serialize } from 'object-to-formdata';
import {API_URL} from "../components/constent";

import axios from "axios";

const config = {
    baseURL: API_URL,
    headers: {
        "Content-type": "application/json",
        //"Content-type": "multipart/form-data",
        'Authorization': 'Bearer ' + (localStorage.hasOwnProperty('user') ? JSON.parse(localStorage.getItem('user')).api_token : '')
    }
}

export const get = (uri = '', params = null) => {
    if(params !== null){
        config.params = params;
    }
    return axios.get(`${uri}`, config).then((response) => {
        return response.data;
    }).catch(error => {
        console.log(error);
        //Toast.fire({icon: 'error', title: error});
    });
};

export const create = (uri, data) => {

    if(data) {
        Object.entries(data).forEach((field, i) => {
            if (field[1] instanceof FileList) {
                data[field[0]] = field[1][0];
                config.headers["Content-type"] = "multipart/form-data";
            }
        })
    }
    const formData = serialize(data);

    return axios.post(uri, formData, config).then((response) => {
        return response.data;
    }).catch(error => {
        console.log(error);
        //Toast.fire({icon: 'error', title: error});
    });
};

export const update = (url, id, data) => {

    if(data) {
        Object.entries(data).forEach((field, i) => {
            if (field[1] instanceof FileList) {
                data[field[0]] = field[1][0];
                config.headers["Content-type"] = "multipart/form-data";
            }
        })
    }
    const formData = serialize(data);

    return axios.put(`${url}/${id}`, formData, config).then((response) => {
        return response.data;
    }).catch(error => {
        console.log(error);
        //Toast.fire({icon: 'error', title: error});
    });
};

export const remove = (uri, id) => {
    return axios.delete(`${uri}/${id}`, config).then((response) => {
        return response.data;
    }).catch(error => {
        console.log(error);
        //Toast.fire({icon: 'error', title: error});
    });
};

export const removeAll = (uri) => {

    return axios.delete(`${uri}/deleteAll`, config).then((response) => {
        return response.data;
    }).catch(error => {
        console.log(error);
        //Toast.fire({icon: 'error', title: error});
    });
};

export const ApiDS = {
    get,
    create,
    update,
    remove,
    //removeAll,
};


export function useQuery() {
    return new URLSearchParams(window.location.search);
}
