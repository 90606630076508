import React, {Fragment, useContext, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {ThemeContext} from "../context/ThemeProvider";

const MetaTags = (props) => {

    const {options} = useContext(ThemeContext);

    useEffect(() => {
        // Update the document title using the browser API
    }, []);

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>

                <meta name="robots" content={options.robots} />
                <link rel="canonical" href={window.location.href} />
                <link rel="shortcut icon" href={options.asset_back_url + 'images/' + options.favicon}/>

                {/*Primary Meta Tags*/}
                <title>{props.title || options.site_title}</title>
                <meta name="title" content={props?.meta_title || options.site_title}/>
                <meta name="description" content={props.meta_description || options.meta_description}/>
                <meta name="keywords" content={props.meta_keywords || options.meta_keywords}/>

                {/*Open Graph / Facebook*/}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:title" content={props.meta_title}/>
                <meta property="og:description" content={props.meta_description || options.meta_description}/>
                <meta property="og:image" content={props.image}/>

                {/*Twitter*/}
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content={window.location.href}/>
                <meta property="twitter:title" content={props.meta_title || options.meta_title}/>
                <meta property="twitter:description" content={props.meta_description || options.meta_description}/>
                <meta property="twitter:image" content={props.image}/>
            </Helmet>
        </Fragment>
    );
}

export default MetaTags;
