import React, {Fragment, useState} from 'react';
import {Link} from "react-router-dom";

const Breadcrumb = (props) => {
    const [items, setItems] = useState(props.items);

    return (
        <div className={'page-title-area ' + (props.cls ? props.cls : 'page-title-area-two')} style={{backgroundImage: props.bg}}>
            <div className="container">
                <div className="page-title-item">
                    <h2>{ props.title }</h2>
                    {/*{items &&
                        <ul>
                            {items.map((item, i, items) => (
                                <Fragment key={i}>
                                    <li><Link to={item.url}>{item.title}</Link></li>
                                    {(i + 1) < items.length &&
                                        <li><span/></li>
                                    }
                                </Fragment>
                            ))}
                        </ul>
                    }*/}
                </div>
            </div>
        </div>
    );
}

export default Breadcrumb;
