import React from 'react';

const About = (props) => {

    return (
        <section className="trust-area pt-100 pb-50">
            <div className="container">
                <div dangerouslySetInnerHTML={{__html: props.response.content}}></div>
            </div>
        </section>
    );
}

export default About;
