import React, {Fragment, useContext, useEffect, useState} from 'react';

import {API_URL} from "../constent";
import {ThemeContext} from "../../context/ThemeProvider";
const ComingSoon = () => {

    const {options} = useContext(ThemeContext);

    const [datetime, setDatetime] = useState('July 30, 2021 00:00:00');

    const counDown = () => {
        // Timer JS
        let getDaysId = document.getElementById('days');
        if(getDaysId !== null){

            const second = 1000;
            const minute = second * 60;
            const hour = minute * 60;
            const day = hour * 24;

            let countDown = new Date(datetime).getTime();
            setInterval(function() {
                let now = new Date().getTime();
                let distance = countDown - now;
                return document.getElementById('days').innerText = Math.floor(distance / (day)),
                document.getElementById('hours').innerText = Math.floor((distance % (day)) / (hour)),
                document.getElementById('minutes').innerText = Math.floor((distance % (hour)) / (minute)),
                document.getElementById('seconds').innerText = Math.floor((distance % (minute)) / second);
            }, second);
        }
    }
    useEffect(() => {
        counDown();
    }, []);

    return (
        <Fragment>
            <section className="coming-area">
                <div className="coming-item" style={{height: 400}}>
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="coming-text">
                                    <h1>Coming Soon...</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea deserunt beatae voluptas, animi harum dolorum totam, praesentium, dolor sint aspernatur perspiciatis iusto labore nulla rerum earum! Sit commodi quia provident!</p>
                                    <div className="row coming-wrap">
                                        <div className="col-6 col-sm-6 col-lg-3">
                                            <div className="coming-inner">
                                                <h3 id="days"></h3>
                                                <p>Days</p>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-6 col-lg-3">
                                            <div className="coming-inner">
                                                <h3 id="hours"></h3>
                                                <p>Hours</p>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-6 col-lg-3">
                                            <div className="coming-inner">
                                                <h3 id="minutes"></h3>
                                                <p>Minutes</p>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-6 col-lg-3">
                                            <div className="coming-inner">
                                                <h3 id="seconds"></h3>
                                                <p>Seconds</p>
                                            </div>
                                        </div>
                                    </div>
                                    {options.social &&
                                    <ul className={'-text-center'}>
                                        {Object.entries(options.social).map((social) => {
                                            if (social[1]) {
                                                return (
                                                    <li key={'social-' + social[0]}><a href={social[1]} target="_blank"><i className={'bx bxl-' + social[0]}/></a></li>
                                                )
                                            }
                                        })}
                                    </ul>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default ComingSoon;
