/**
 * ReactJS Context API with Hooks
 * https://github.com/AlexSegen/react-shopping-cart/
 */
import React, {createContext, useReducer} from "react";


// Login Reducer
export const UserReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN_LOGOUT':
            return action.payload;
        case 'UPDATE_DATA':
            return {...state, ...action.payload};
        default:
            return state;
    }
};


// Create User Context
export const UserContext = createContext();


const initialState = JSON.parse(localStorage.getItem('user'));

const UserProvider = (props) => {

    const [state, dispatch] = useReducer(UserReducer, initialState);

    const login = payload => {
        dispatch({type: 'LOGIN_LOGOUT', payload})
    }

    const logout = payload => {
        payload = {};
        dispatch({type: 'LOGIN_LOGOUT', payload})
    }

    const update = payload => {
        dispatch({type: 'UPDATE_DATA', payload});
        localStorage.setItem('user', JSON.stringify({...state, ...payload}));
    }



    const contextValues = {
        login,
        logout,
        update,
        ...state
    }

    return (
        <UserContext.Provider value={contextValues}>
            {props.children}
        </UserContext.Provider>
    );
};

export default UserProvider;

