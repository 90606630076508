import React, {Fragment, useRef} from 'react';
import useFetch from "../../hooks/useFetch";
import {API_URL} from "../constent";
import OwlCarousel from "react-owl-carousel2";

const Testimonials = () => {
    const { data, loading } = useFetch(API_URL + '/testimonials/?limit=4');//s[featured]=1&f[featured]==&

    const sliderEl = useRef('testimonials');

    const options = {
        items: 1,
        dots: false,
        nav: false,
        smartSpeed: 200,
        slideSpeed: 500,
        slideBy: 1,
        autoplay: true,
        responsiveRefreshRate: 100,
    };

    const events = {
        //onDragged: function(event) {...},
        //onChanged: function(event) {...}
    };

    return (
        <Fragment>

            {data && !loading &&
            <section className="testimonial-area ptb-100">
                <div className="container">
                    <div className="section-title">
                        <span className="sub-title">Testimonial</span>
                        <h2>What Customer’s Say</h2>
                    </div>
                    <OwlCarousel id="thumbs" className="testimonial-slider owl-carousel owl-theme" ref={sliderEl} options={options} events={events}>
                        {data.response.data.map(row => (
                            <div className="testimonial-item"  key={row.id}>
                                <i className='bx bxs-quote-alt-left' />
                                <p>{row.testimonial}</p>
                                <h3>{row.name}</h3>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </section>
            }
        </Fragment>
    );
}

export default Testimonials;
