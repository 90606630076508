import React, {Fragment, useEffect, lazy, Suspense} from 'react';

import Banner from "./Banner";
import About from "./About";
import Testimonials from "./Testimonials";

import {API_URL, SITE_TITLE} from "../constent";
import useFetch from "../../hooks/useFetch";
import MetaTags from "../../helpers/MetaTags";
import Signup from "../member/Signup";


const Home = (props) => {
    //const {page} = props.match.params;
    const {data, loading} = useFetch(API_URL + '/page/landing');

    useEffect(() => {
        document.title = SITE_TITLE
    });

    return (
        <div>
            <Suspense fallback={<div/>}>
                <Banner/>
            </Suspense>
            {data &&
            <Fragment>
                <Suspense fallback={<div/>}>
                    <About {...data} />
                </Suspense>
                <MetaTags title={data.response.title}
                          meta_title={data.response.meta_title}
                          meta_description={data.response.meta_description}
                          meta_keywords={data.response.meta_keywords}
                          image={data.response.thumbnailUrl}
                />
            </Fragment>
            }

            {/*<Suspense fallback={<div/>}>
                <News/>
            </Suspense>*/}

            {/*<div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <Signup />
                    </div>
                </div>
            </div>*/}

            <Testimonials/>
        </div>
    );
};

export default Home;
