import React, {Fragment, useContext, useEffect, useState} from 'react';
import TopMenu from "./TopMenu";
import {ThemeContext} from "../context/ThemeProvider";
import {UserContext} from "../context/UserProvider";
import {Link} from "react-router-dom";
import TopHeader from "./TopHeader";

const Header = () => {
    const {options} = useContext(ThemeContext);
    const [logo, setLogo] = useState(null);

    const user = useContext(UserContext);

    useEffect(() => {
        setLogo(options.logo);
    }, [options]);

    return (
        <Fragment>
            <div className="navbar-area fixed-top">
                {/*<TopHeader/>*/}
                <div className="mobile-nav mobile-none">
                    <Link className="logo" to="/">
                        {logo && <img src={options.asset_back_url + 'images/' + logo} alt={options.site_title}/>}
                    </Link>
                </div>
                <div className="main-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <div className="mean-push"/>
                            <div className="collapse navbar-collapse mean-menu justify-content-between" id="navbarSupportedContent">
                                <TopMenu/>

                                <div className="topsocial">
                                    {options?.social &&
                                    Object.entries(options.social).map((social) => {
                                        if (social[1]) {
                                            return (
                                                <a href={social[1]} target="_blank" key={'top-s-' + Math.random()}><i className={'bx bx-sm bxl-' + social[0]}/></a>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

                <div className="mobile-nav mean-container menu-shrink">
                    <div className="mean-bar">
                        <a href="#nav" className="meanmenu-reveal">
                            <span/><span/><span/>
                        </a>
                        <nav className="mean-nav">
                            <TopMenu load="mobile" />
                        </nav>

                        <a href="#profile-nav" className="profile-nav">
                            <img src={user?.photoURL} alt={user?.full_name} className="rounded-circle -img-thumbnail" width="40"/> Profile
                        </a>
                    </div>
                    <Link className="logo" to="/">
                        {logo && <img src={options.asset_back_url + 'images/' + logo} alt={options.site_title}/>}
                    </Link>
                </div>

            </div>
        </Fragment>
    );
}

export default Header;
