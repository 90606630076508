import React, {Fragment, useContext, useEffect, useState} from 'react';

import {API_URL} from "../constent";
import useFetch from "../../hooks/useFetch";
import Breadcrumb from "./Breadcrumb";
import Loader from "../Loader";

import MetaTags from "../../helpers/MetaTags";
import {ThemeContext} from "../../context/ThemeProvider";
import ComingSoon from "./ComingSoon";


const Page = (props) => {

    const {options} = useContext(ThemeContext);
    const directPages = ['login', 'signup', 'forgot'];

    const { page }  = (props?.match?.params || '404');
    const { data, loading, error } = useFetch(API_URL + '/page/' + page);

    const [breadCrumb, setBreadCrumb] = useState([{ url: '/', title: 'Home' }]);

    useEffect(() => {
        if(data) {
            setBreadCrumb([{ url: '/', title: 'Home' }, {url: '/' + data.response.slug, title: data.response.title}]);
        }
    }, [data]);

    return (
        <div>
            {loading && directPages.indexOf(page) < 0 && <Loader/>}
            {data && !loading &&
                <Fragment>

                    <MetaTags title={data.response.title || options.site_title}
                    meta_title={data.response.meta_title || options.site_title}
                    meta_description={data.response.meta_description || options.meta_description}
                    meta_keywords={data.response.meta_keywords || options.meta_keywords}
                    image={data.response.thumbnailUrl}
                    />

                    <Breadcrumb items={breadCrumb} title={data.response.title}  bg={data.response.thumbnailUrl} />

                    <div className="-about-me-area">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="-about-me-content">
                                        {data.response.show_title > 1 &&
                                            <h2>{data.response.title}</h2>
                                        }

                                        {(() => {
                                            switch (page) {
                                                case "investors":
                                                    return <Fragment>
                                                        <div className="col-lg-12" dangerouslySetInnerHTML={{__html: data.response.content}}/>
                                                        <ComingSoon />
                                                    </Fragment>;
                                                default:
                                                    return <div dangerouslySetInnerHTML={{__html: data.response.content}}></div>;
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </div>
    );
}

export default Page;
