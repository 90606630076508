import React, {useContext, useEffect, useRef} from 'react';
import useFetch from "../../hooks/useFetch";
import {API_URL} from "../constent";
import OwlCarousel from "react-owl-carousel2";
import {Link} from "react-router-dom";
import {ThemeContext} from "../../context/ThemeProvider";

//import ThemeProvider, { ThemeContext } from "./../../context/ThemeProvider";

const Banner = () => {

    const {options} = useContext(ThemeContext);

    const { data, loading, error } = useFetch(API_URL + '/banners/?s[type]=Landing&f[featured]==');

    //const Theme = useContext(ThemeContext);

    useEffect(() => {
        // Update the document title using the browser API
    }, []);

    //const sliderEl = createRef('talents');
    const sliderEl = useRef('banner');

    const OWLoptions = {
        items: 1,
        margin: 0,
        dots: true,
        loop: true,
        nav: false,
        smartSpeed: 200,
        slideSpeed: 500,
        slideBy: 1,
        autoplay: true,
        responsiveRefreshRate: 100,
    };

    const events = {
        //onDragged: function(event) {...},
        //onChanged: function(event) {...}
    };

    return (

        <div className="banner-wrap">
            {data &&
            <OwlCarousel id="thumbs" className="banner-slider owl-theme owl-carousel" ref={sliderEl} options={OWLoptions} events={events}>
                {data.response.data.map(row => (
                    <div className="banner-area -banner-img-one" style={{backgroundImage: "url(" + row.imageURL + ")"}} key={row.id}>
                        <div className="d-table">
                            <div className="d-table-cell">
                                <div className="banner-content">
                                    <h1>{row.title}</h1>
                                    <div dangerouslySetInnerHTML={{__html: row.description}}/>
                                    {row.link && row.link.length > 0 &&
                                        <Link to={row.link} className="banner-btn">Read More</Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
            }

            {options.social &&
            <div className="banner-social">
                <span>Follow us on:</span>
                <ul className={'-text-center'}>
                    {Object.entries(options.social).map((social) => {
                        if (social[1]) {
                            return (
                                <li key={'social-' + social[0]}><a href={social[1]} target="_blank"><i className={'bx bxl-' + social[0]}/></a></li>
                            )
                        }
                    })}
                </ul>
            </div>
            }

            <div className="lines">
                <div className="line"/>
                <div className="line"/>
                <div className="line"/>
                <div className="line"/>
                <div className="line"/>
                <div className="line"/>
                <div className="line"/>
                <div className="line"/>
                <div className="line"/>
            </div>
        </div>
    );
}

export default Banner;
