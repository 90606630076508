import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: async (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)

        let audio = new Audio();
        audio.src = "/assets/audio/click.mp3";
        audio.volume = (localStorage.getItem('volume') || 1);
        await audio.play();
    }
});

export default Toast;


export const SwConfirm = Swal.mixin({
    /*customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false,*/
    didOpen: (toast) => {

        let audio = new Audio();
        audio.src = "/assets/audio/click.mp3";
        audio.volume = (localStorage.getItem('volume') || 1);
        audio.play();
    }
})
