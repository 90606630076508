import React, {useEffect, useState} from 'react';

const useFetch = (url, options = {}) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await fetch(url, options);
            const json = await res.json();
            setData(json);
            setLoading(false)
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [url]);
    return {data, error, loading};
};
export default useFetch;
