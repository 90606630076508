import React, {Fragment, useEffect, useState} from 'react';

import {API_URL} from "../constent";
import useFetch from "../../hooks/useFetch";
import Breadcrumb from "./Breadcrumb";
import Loader from "../Loader";

/**‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
 | Load Listing Page
 *‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒*/


const Page404 = (props) => {
    const { data, loading, error } = useFetch(API_URL + '/page/404');

    const [breadCrumb, setBreadCrumb] = useState([{ url: '/', title: 'Home' }]);

    useEffect(() => {
        if(data) {
            document.title = data.response.meta_title;
            //setBreadCrumb(breadCrumb.push({url: data.response.slug, title: data.response.title}));
        }
    }, [data]);

    return (
        <div>
            {loading && <Loader/>}

            {data && !loading &&
                <Fragment>

                    {/*<Breadcrumb title={data.response.title}  bg={data.response.thumbnailUrl} />*/}

                    <div className="-about-me-area pt-100 pb-70">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="-about-me-content">
                                        <h2>{data.response.title}</h2>
                                        <div dangerouslySetInnerHTML={{__html: data.response.content}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </div>
    );
}

export default Page404;
