import React, {useContext, useEffect, useState, Suspense, lazy} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

/*import 'sweetalert2/src/sweetalert2.scss'
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";
import "@fancyapps/fancybox/dist/jquery.fancybox.min";*/
/**‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
 | Load Component
 *‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒*/
import Header from "./components/Header";
import Footer from "./components/Footer";

/**‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
 | Pages
 *‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒*/
//import Home from "./home/Home";

//const Home = lazy(() => import("./components/home/Home"));
import Home from "./components/home/Home";
import Page from "./components/pages/Page";
/**‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
 | Store data
 *‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒*/
import ThemeProvider, { ThemeContext } from "./context/ThemeProvider";
import Page404 from "./components/pages/Page404";

//import {PrivateRoute} from "../hooks/useAuth";

function App(props) {

    const Theme = useContext(ThemeContext);

    useEffect(() => {
        document.title = Theme.options.site_title;
    }, []);

    return (
        <Router>

            <Header />

            <Suspense fallback={<div/>}>
                <Switch>
                    <Route exact path="/" component={Home} />

                    <Route exact path="/:page" render={(props) => {
                        /**
                         * Listing & Page View
                         */
                        //setFullPage(true);
                        switch (props.match.params.page) {
                            default:
                                return (<Page {...props} />);
                        }
                    }} />

                    <Route path="*"><Page404 /></Route>

                </Switch>
            </Suspense>

            <Footer />

        </Router>
    );
}

export default App;
