import React, {Fragment, useContext, useEffect, useState} from 'react';
import useFetch from "../hooks/useFetch";
import {API_URL} from "./constent";
import {Link} from "react-router-dom";
import {ThemeContext} from "../context/ThemeProvider";
import Toast from "../helpers/SwalTost";
import axios from "axios";

const TopMenu = (props) => {

    const {options} = useContext(ThemeContext);

    const [URL, setURL] = useState(API_URL + '/menu/Landing Menu');

    const [menu, setMenu] = useState(sessionStorage.hasOwnProperty('main_menu') ? JSON.parse(sessionStorage.getItem('main_menu')) : null);
    useEffect(() => {
        if(!sessionStorage.hasOwnProperty('main_menu')) {
            const config = {headers: {"Content-type": "application/json"}}
            axios.get(URL, config).then((response) => {
                setMenu(response.data.response);
                sessionStorage.setItem('main_menu', JSON.stringify(response.data.response));
            })
        }
    }, []);

    useEffect(() => {

    }, []);

    return (
        <Fragment>
            {menu && options &&
            <ul className="navbar-nav" style={{display: (props.load === 'mobile' ? 'none' : '')}}>
                <div className="navbar-logo d-none d-sm-block d-sm-none d-md-none d-lg-block">
                    <Link className="navbar-brand" to="/">
                        <img src={options.asset_back_url + 'images/' + options.logo} alt={options.site_title} className="img-fluid"/>
                    </Link>
                </div>
                {BuildNav(menu)}
            </ul>
            }
        </Fragment>
    );
};

export default TopMenu;

const BuildNav = (rows) => {
    return (
        <Fragment>
            {rows?.map((row) => (
                <li className={'nav-item nav-slug-' + row.slug} id={'menu-id-' + row.id} key={row.id + Math.random()}>
                    <Link to={(typeof row.slug === 'undefined' ? '/' : '/' + row.slug)} className="nav-link">{row.menu_title}
                    {row.children && <i className="bx bx-chevron-down"/>}
                    </Link>
                    {row.children &&
                    <ul className="dropdown-menu">
                        {BuildNav(row.children)}
                    </ul>
                    }
                </li>
            ))}
        </Fragment>
    );
};
