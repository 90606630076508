import React, {useContext, useEffect, useState, lazy, Suspense} from 'react';
import {ThemeContext} from "../context/ThemeProvider";
import {Link} from "react-router-dom";
import FetchBlock from "../hooks/FetchBlock";
const Subscribe = lazy(() => import("./Subscriber"));

const Footer = () => {

    const {options} = useContext(ThemeContext);

    const [logo, setLogo] = useState(null);

    useEffect(() => {
        setLogo(options.footer_logo);
    }, [options]);

    return (
        <div>
            <footer className="pt-50">
                <Suspense fallback={<div/>}>
                    <Subscribe />
                </Suspense>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-lg-3">
                            <div className="footer-item">
                                <div className="footer-logo">
                                    <Link className="footer-logo-img" to="/">
                                        {logo &&
                                            <img src={options.asset_back_url + 'images/' + logo} alt={options.site_title}/>
                                        }
                                    </Link>
                                    <FetchBlock block={'about-footer'} />

                                    {options.social &&
                                    <ul className={'-text-center'}>
                                        {Object.entries(options.social).map((social) => {
                                            if (social[1]) {
                                                return (
                                                    <li key={'social-' + social[0]}><a href={social[1]} target="_blank"><i className={'bx bxl-' + social[0]}/></a></li>
                                                )
                                            }
                                        })}
                                    </ul>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            <div className="footer-item">
                                <div className="footer-link">
                                    <h3>Facebook Feed</h3>
                                    <div className="text-center">
                                        <div className="fb-page" data-href="https://www.facebook.com/GlobalEntertainmentMovement/" data-tabs="timeline" data-width="" data-height="335" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                                            <blockquote cite="https://www.facebook.com/GlobalEntertainmentMovement/" className="fb-xfbml-parse-ignore">
                                                <a href="https://www.facebook.com/GlobalEntertainmentMovement/">GEM - Global Entertainment Movement</a>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            <div className="footer-item">
                                <div className="footer-link">
                                    <h3>Twitter Feed</h3>
                                    <div className="text-center">
                                        <a className="twitter-timeline" data-height="335" href="https://twitter.com/GlobalE70468693?ref_src=twsrc%5Etfw">Tweets by GlobalE70468693</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            <div className="footer-item">
                                <div className="footer-link">
                                    <h3>Instagram Feed</h3>
                                    <div className="text-center">
                                        <div className="elfsight-app-f70a46c5-cd7e-4943-b4c6-bfe07541ca92 text-center">Insta Feeds</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-sm-6 col-lg-3">
                            <div className="footer-item">
                                <div className="footer-link">
                                    <h3>Useful Links</h3>
                                    <ul>
                                        <li><a href="" target="_blank">Home</a></li>
                                        <li><a href="" target="_blank">About Us</a></li>
                                        <li><a href="" target="_blank">Overview</a></li>
                                        <li><a href="" target="_blank">Team</a></li>
                                        <li><a href="" target="_blank">Investors</a></li>
                                        <li><a href="" target="_blank">Benefits</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            <GetInTouch />
                        </div>*/}
                    </div>
                </div>
            </footer>

            <div className="copyright-area">
                <div className="container">
                    <div className="copyright-item">
                        <p dangerouslySetInnerHTML={{__html: options.copyright}}></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
