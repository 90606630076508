/**
 * ReactJS Context API with Hooks
 * https://www.youtube.com/watch?v=10fvaD3q_O8&t=2816s
 * https://github.com/toofaniCoder/react-context-todo
 */
import React, {createContext, useEffect, useReducer} from "react";
import * as appConst from "../components/constent";

// Reducer
const OptionsReducer = (state, action) => {
    switch (action.type) {
        case 'LOAD_OPTIONS':
            return {...state, ...action.payload};
        default:
            return state;
    }
};

export const ThemeContext = createContext();

const ThemeProvider = (props) => {

    const initialState = {
        options: {site_title: appConst.SITE_TITLE},
    };

    const [state, dispatch] = useReducer(OptionsReducer, initialState);

    useEffect(() => {
        //console.log('Load Use');
        // Update the document title using the browser API
        fetch(appConst.API_URL + '/options')
            .then(res => res.json()).then((result) => {
            dispatch({type: 'LOAD_OPTIONS', payload: {options: result}})
        })

    }, []);

    return (
        <ThemeContext.Provider
            value={{
                options: state.options,
            }}
        >
            {props.children}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;



